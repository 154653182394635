define('newcar-www:static/js/common/footer.es6', function(require, exports, module) {

  
  
  function footerIcoLazyload() {
      $(".n_f_m_app img,.n_f_m_wx img,.n_f_m_i img").lazyload();
  }
  
  module.exports = {
      footerIcoLazyload: footerIcoLazyload
  };

});
